import React from 'react';
import * as antd from 'antd';

import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

interface lesson {
  id: number;
  name: string;
  counties: string;
  district: string;
  section: string;
  durationStart: string;
  durationEnd: string;
  introduction: string;
  user: User;
  numType: string;
  classType: string;
}
const LessonModal = ({ lesson }: { lesson: lesson }) => {
  React.useEffect(() => {
    const init = async () => {};
    init();
  });
  return (
    <antd.Descriptions title="課程內容" bordered size={'small'} column={2}>
      <antd.Descriptions.Item label="課程人數">
        {lesson.numType === 'single' ? '一對一' : '一對二'}
      </antd.Descriptions.Item>
      <antd.Descriptions.Item label="課程類型" span={2}>
        {lesson.classType}
      </antd.Descriptions.Item>
      <antd.Descriptions.Item label="課程地點" span={2}>
        {`${lesson.counties} ${lesson.district} ${lesson.section}`}
      </antd.Descriptions.Item>
      <antd.Descriptions.Item label="課程簡介" span={2}>
        {lesson.introduction}
      </antd.Descriptions.Item>
    </antd.Descriptions>
  );
};

export { LessonModal };
