import React from 'react';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
import { EditRegistTime } from '../modals/EditRegistTime';
import { EditRegistLocation } from '../modals/EditRegistLocation';

const { Option } = antd.Select;

interface regist {
  id: number;
  date: Date;
  timeStart: Date;
  timeEnd: Date;
  lesson: lesson;
  user: user;
}

interface lesson {
  name: string;
  user: user;
}
interface user {
  name: string;
}

export const RegistsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<regist[]>([]); //coulmns data

  const [input, setInput] = React.useState<string>('');
  const [select, setSelect] = React.useState<string>('1');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (page - 1)).toString());
      url.append('limit', pageSize.toString());
      url.append('status', 'reserve');
      switch (select) {
        case '1':
          url.append('name', input);
          break;
        case '2':
          url.append('coachName', input);
          break;
        default:
          url.append('userName', input);
          break;
      }

      let result = await appCtx.fetch('get', '/api/admin/lessonregists?' + url.toString());
      setLoading(false);
      if (result) {
        console.log(result);
        setTotal(result.total);
        setDataSource(result.regists);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  const cancelRegist = async (id: number) => {
    try {
      let data = await appCtx.fetch('delete', `/api/admin/lessonregist/${id}`);
      if (data) {
        initialize();
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const checkStatus = (startTime: dayjs.Dayjs, endTime: dayjs.Dayjs): string => {
    if (dayjs() > endTime) {
      return '課程結束';
    }
    if (dayjs() > startTime) {
      return '課程進行中';
    }

    if (dayjs().diff(startTime, 'm', true) > 5) {
      return '不可取消或調課';
    }

    return '尚未上課';
  };

  const columns: ColumnsType<regist> = [
    {
      title: '課程名稱',
      align: 'center',
      render: (item) => <>{item.lesson.name}</>,
    },
    {
      title: '課程狀態',
      align: 'center',
      render: (item) => (
        <antd.Tag
          color={
            item.status === 'normal' ? 'green' : item.status === 'cancel' ? 'purple' : 'magenta'
          }
        >
          {item.status === 'normal'
            ? checkStatus(dayjs(item.timeStart), dayjs(item.timeEnd))
            : item.status === 'cancel'
            ? '課程取消'
            : '調課'}
        </antd.Tag>
      ),
    },
    {
      title: '教練',
      align: 'center',
      render: (item) => <>{item.lesson.user.name}</>,
    },
    {
      title: '學員',
      align: 'center',
      render: (item) => <>{item.user.name}</>,
    },
    {
      title: '上課時間',
      align: 'center',
      render: (item) => (
        <>
          <>{dayjs(item.timeStart).format('YYYY-MM-DD')}</>
          <br />
          <>{dayjs(item.timeStart).format('HH:mm') + '~' + dayjs(item.timeEnd).format('HH:mm')}</>
        </>
      ),
    },
    {
      title: '上課地點',
      align: 'center',
      render: (item) => (
        <>
          {item.counties}
          {item.district}
          {item.section}
        </>
      ),
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <antd.Button
          disabled={dayjs().diff(dayjs(item.startTime), 'm', true) > 5}
          type="primary"
          onClick={() =>
            appCtx.setModal(
              <EditRegistTime
                onSuccess={initialize}
                name={item.lesson.name}
                timeStart={new Date(item.timeStart)}
                timeEnd={new Date(item.timeEnd)}
                id={item.id}
              />,
            )
          }
        >
          修改預約時間
        </antd.Button>
      ),
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <antd.Button
          disabled={dayjs().diff(dayjs(item.startTime), 'm', true) > 5}
          type="primary"
          onClick={() =>
            appCtx.setModal(
              <EditRegistLocation
                onSuccess={initialize}
                name={item.lesson.name}
                counties={item.counties}
                district={item.district}
                section={item.section}
                id={item.id}
              />,
            )
          }
        >
          修改上課地點
        </antd.Button>
      ),
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <DangerButton
          disabled={item.status === 'cancel' || dayjs().diff(dayjs(item.startTime), 'm', true) > 5}
          title={'取消課程'}
          message={'確定取消課程並退款給用戶?'}
          onClick={() => cancelRegist(item.id)}
        />
      ),
    },
  ];

  const SelectAfter = (
    <antd.Select
      defaultValue={select}
      style={{ width: 120 }}
      onChange={(select) => setSelect(select)}
    >
      <Option value="1">課程名稱</Option>
      <Option value="2">教練名稱</Option>
      <Option value="3">學員名稱</Option>
    </antd.Select>
  );

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        <div className="w-3/12">
          <antd.Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            allowClear
            addonBefore={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};
