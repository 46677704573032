import React from 'react';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const { RangePicker } = antd.DatePicker;

interface EditRegistLocationProps {
  id: number;
  name: string;
  counties: string;
  district: string;
  section: string;
  onSuccess: Function;
}

export const EditRegistLocation = ({
  onSuccess,
  name,
  counties,
  district,
  section,
  id,
}: EditRegistLocationProps) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    const data = await appCtx.fetch('patch', `/api/admin/lessonregist/${id}`, {
      counties: values.counties,
      district: values.district,
      section: values.section,
    });

    if (data) {
      Notification.add('success', '修改成功');
      onSuccess();
    }
  };

  return (
    <antd.Form onFinish={onFinish} initialValues={{ counties, district, section }}>
      <h5 className="font-weight-bold mb-4">修改{name}上課地點</h5>

      <antd.Form.Item
        name="counties"
        label="城市"
        rules={[{ required: true, message: '請輸入城市' }]}
      >
        <antd.Input prefix={<i className="fa fa-map-marker" />} placeholder="請輸入城市" />
      </antd.Form.Item>

      <antd.Form.Item
        name="district"
        label="市區"
        rules={[{ required: true, message: '請輸入市區' }]}
      >
        <antd.Input prefix={<i className="fa fa-compass" />} placeholder="請輸入市區" />
      </antd.Form.Item>

      <antd.Form.Item
        name="section"
        label="街道"
        rules={[{ required: true, message: '請輸入街道' }]}
      >
        <antd.Input prefix={<i className="fa fa-building" />} placeholder="請輸入街道" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="修改" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};
