import React from 'react';
import * as antd from 'antd';
import moment from 'moment';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const { RangePicker } = antd.DatePicker;

interface classTime {
  dayOfWeek: string;
  start: Date;
  end: Date;
}

interface EditLessonTimeProps {
  id: number;
  name: string;
  durationStart: Date;
  durationEnd: Date;
  classTimes: classTime[];
  onSuccess: Function;
}

export const EditLessonTime = ({
  id,
  name,
  durationStart,
  durationEnd,
  classTimes,
  onSuccess,
}: EditLessonTimeProps) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    if (values.dayOfWeeks.length === 0) {
      Notification.add('error', '請至少選擇一個星期');
      return;
    }

    // clean minute/second/millisecond
    const timeStart = moment(moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss')).toISOString();
    const timeEnd = moment(moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss')).toISOString();

    let durations = [];
    for (const dayOfWeek of values.dayOfWeeks) {
      durations.push({
        dayOfWeek,
        start: timeStart,
        end: timeEnd,
      });
    }

    const data = await appCtx.fetch('put', `/api/admin/lesson/${id}`, {
      durationStart: moment(moment(values.duration[0]).format('YYYY-MM-DD')).toISOString(),
      durationEnd: moment(moment(values.duration[1]).format('YYYY-MM-DD')).toISOString(),
      durations,
    });

    if (data) {
      Notification.add('success', '修改成功');
      onSuccess();
    }
  };

  const optionsWithDisabled = [
    { label: '星期天', value: 'Sunday' },
    { label: '星期一', value: 'Monday' },
    { label: '星期二', value: 'Tuesday' },
    { label: '星期三', value: 'Wednesday' },
    { label: '星期四', value: 'Thursday' },
    { label: '星期五', value: 'Friday' },
    { label: '星期六', value: 'Saturday' },
  ];

  return (
    <>
      <h5 className="font-weight-bold mb-4">修改{name}課程時間</h5>

      <antd.Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          duration: [moment(durationStart), moment(durationEnd)],
          time: [moment(classTimes[0].start), moment(classTimes[0].end)],
          dayOfWeeks: classTimes.map((item) => item.dayOfWeek),
        }}
        onFinish={onFinish}
      >
        <antd.Form.Item
          label="選擇上課日期"
          name="duration"
          rules={[{ required: true, message: '請選擇上課日期!' }]}
        >
          <RangePicker format="YYYY-MM-DD" />
        </antd.Form.Item>

        <antd.Form.Item
          label="選擇上課時間"
          name="time"
          rules={[{ required: true, message: '請選擇上課時間!' }]}
        >
          <antd.TimePicker.RangePicker format="HH:00" order={false} />
        </antd.Form.Item>

        <antd.Form.Item name="dayOfWeeks" wrapperCol={{ offset: 8, span: 16 }}>
          <antd.Checkbox.Group
            options={optionsWithDisabled}
            // value={dayOfWeeks}
            // onChange={(checkedValues: any) => setDayOfWeeks(checkedValues)}
          />
        </antd.Form.Item>

        <antd.Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <antd.Button type="primary" htmlType="submit">
            修改
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </>
  );
};
