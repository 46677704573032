import React from 'react';
import * as antd from 'antd';

import moment from 'moment';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const LessonPrice = ({
  onSuccess,
  name,
  price,
  durationStart,
  durationEnd,
}: {
  onSuccess: Function;
  name?: String;
  price?: number;
  durationStart?: Date;
  durationEnd?: Date;
}) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    let data = null;
    data = await appCtx.fetch('post', `/api/admin/lessonprice`, {
      name: values.name,
      price: values.price,
      durationStart: moment(values.durationStart).toISOString(),
      durationEnd: moment(values.durationEnd).toISOString(),
    });
    if (data) {
      Notification.add('success', '新增成功');
      onSuccess();
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 3,
      },
    },
  };

  return (
    <antd.Form
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        name,
        price,
        durationStart: durationStart && moment(durationStart),
        durationEnd: durationEnd && moment(durationEnd),
      }}
    >
      <h5 className="font-weight-bold mb-4">{'新增活動(活動新增後不可刪除或修改)'}</h5>

      <antd.Form.Item
        name="name"
        label="活動名稱"
        rules={[{ required: true, message: '請輸入活動名稱!' }]}
      >
        <antd.Input placeholder="請輸入活動名稱" />
      </antd.Form.Item>

      <antd.Form.Item
        name="price"
        label="課程價格"
        rules={[{ required: true, message: '請輸入課程價格!' }]}
      >
        <antd.InputNumber min={0} />
      </antd.Form.Item>

      <antd.Form.Item
        name="durationStart"
        label="活動起始時間"
        rules={[{ required: true, message: '請輸入活動起始時間!' }]}
      >
        <antd.DatePicker style={{ width: 150 }} placeholder="起始日期" />
      </antd.Form.Item>

      <antd.Form.Item
        name="durationEnd"
        label="活動結束時間"
        rules={[{ required: true, message: '請輸入活動結束時間!' }]}
      >
        <antd.DatePicker style={{ width: 150 }} placeholder="結束日期" />
      </antd.Form.Item>

      <antd.Form.Item
        label="確認活動內容"
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('請勾選確認活動內容')),
          },
        ]}
      >
        <antd.Checkbox> </antd.Checkbox>
      </antd.Form.Item>

      <antd.Form.Item className="text-center" {...tailFormItemLayout}>
        <PrimaryButton title={'新增'} htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};
