import React from 'react';
import axios from 'axios';
import * as antd from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import * as cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, DefaultOptions } from '@apollo/client';
import { API_DOMAIN } from './config';

import { Notification } from './components/Notification';
import { AdminToken } from './services/adminToken';

interface AppContextProps {
  loginPage: string;
  homePage: string;
  setModal: (modal: any, number?: number) => void;
  setModalWidth: React.Dispatch<React.SetStateAction<number>>;

  account: string;
  setAccount: (value: string) => void;
  isAdmin: boolean;
  setIsAdmin: (value: boolean) => void;

  fetch: (
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    param?: any,
  ) => Promise<any>;

  login: (account: string, password: string, token: string) => Promise<any>;
  logout: () => Promise<void>;
  redirect: () => Promise<void>;

  apolloClient: ApolloClient<NormalizedCacheObject>;
}

const AppContext = React.createContext<AppContextProps>(undefined!);

interface AppProviderProps {
  children: React.ReactNode;
}

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAu2wfNCYVeVMc_Nd30p0OyVqXcy-ZWUp4',
        authDomain: 'peman-prod.firebaseapp.com',
        projectId: 'peman-prod',
        storageBucket: 'peman-prod.appspot.com',
        messagingSenderId: '774608386556',
        appId: '1:774608386556:web:c1ce2433e444acb03c1a79',
        measurementId: 'G-XQX9NNF55P',
      }
    : process.env.REACT_APP_ENV === 'beta'
    ? {
        apiKey: 'AIzaSyD_5GL4JcXi26q1VcHGRxDMrzlGKwMyR5o',
        authDomain: 'peman-e6e22.firebaseapp.com',
        projectId: 'peman-e6e22',
        storageBucket: 'peman-e6e22.appspot.com',
        messagingSenderId: '898673202731',
        appId: '1:898673202731:web:d3851de4d8c7ccb86bfc6e',
        measurementId: 'G-D6SJCJFRE3',
      }
    : {
        apiKey: 'AIzaSyCuirOfpXKMQYlgHYX7WHvpyXdvx2gXozM',
        authDomain: 'peman-dev.firebaseapp.com',
        projectId: 'peman-dev',
        storageBucket: 'peman-dev.appspot.com',
        messagingSenderId: '960363556382',
        appId: '1:960363556382:web:cd550f29d93011393a080f',
        measurementId: 'G-LWNT3KFW0V',
      };

firebase.initializeApp(firebaseConfig);

// Initialize Firebase
export const app = firebase;
export const storage = firebase.storage();

const AppProvider = ({ children }: AppProviderProps) => {
  const [loginPage] = React.useState('/#/login');
  const [homePage] = React.useState('/#/agents');
  const [modal, setModal] = React.useState<any>(null);
  const [modalWidth, setModalWidth] = React.useState<number>(520);

  const [account, setAccount] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);

  /////////////////////////////////////////////////////

  React.useEffect(() => {
    redirect();
  }, []);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  // index也有一個每hook用的設定,找時間要決定怎麼做
  const apolloClient = new ApolloClient({
    uri: API_DOMAIN + '/graphql',
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  const fetch = async (
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    param?: any,
  ) => {
    let data: any = null;

    try {
      const response = await axios({
        method,
        url,
        data: param,
        headers: {
          authorization: AdminToken.get(),
        },
      });
      console.log('response', response.data);

      if (response.data.errorCode === 9999) {
        window.location.href = loginPage;
        return null;
      }

      if (response.data.errorCode !== 0) {
        throw new Error(response.data.errorMessage);
      }

      data = response.data;
    } catch (error: any) {
      Notification.add('error', error.message);
    } finally {
      AdminToken.checkExpire();
    }

    return data;
  };

  const login = async (account: string, password: string, token: string): Promise<any> => {
    const data = await fetch('post', `/api/admin/login`, {
      id: account,
      password,
      token,
    });

    if (data) {
      if (data.errorCode === 0) {
        setAccount(account);
        setIsAdmin(data.sign.user.admin);
        cookie.set('user', { id: account, admin: data.sign.user.admin });
        AdminToken.set(data.token);
        Notification.add('success', '驗證成功');
        window.location.href = homePage;
      } else {
        window.location.href = loginPage;
      }
    } else {
      window.location.href = loginPage;
    }
  };

  const logout = async () => {
    await fetch('post', '/api/admin/logout');
    AdminToken.remove();
    window.location.href = loginPage;
  };

  const redirect = async () => {
    let data = await fetch('get', '/api/admin/redirect');
    if (!data) {
      window.location.href = loginPage;
    }
  };

  /////////////////////////////////////////////////////

  return (
    <AppContext.Provider
      value={{
        loginPage,
        homePage,
        setModal: (modal: any, width: number = 520) => {
          setModal(modal);
          setModalWidth(width);
        },
        setModalWidth,

        account,
        setAccount,
        isAdmin,
        setIsAdmin,

        fetch,

        login,
        logout,
        redirect,

        apolloClient,
      }}
    >
      {modal && (
        <antd.Modal
          visible={modal !== null}
          onOk={() => setModal(null)}
          onCancel={() => setModal(null)}
          footer={null}
          closable={false}
          width={modalWidth}
        >
          {modal}
        </antd.Modal>
      )}

      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
