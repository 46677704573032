import { ApiClient } from './apiClient';

type usedStatus = 'none' | 'used' | 'unused';

export interface CouponFetchOptions {
  user?: string;
  status?: usedStatus;
  limit?: number;
  offset?: number;
}

export class Coupon {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async fetch(options?: CouponFetchOptions) {
    const result = await this.apiClient.get('/api/admin/coupon', {
      ...options,
      ...(options?.status === 'none' && { status: undefined }),
    });
    if (result.data.errorCode !== 0) throw new Error(result.data.errorMessage);
    return { data: result.data.data, total: result.data.total };
  }

  async fetchOne(id: number) {
    const result = await this.apiClient.get(`/api/admin/coupon/${id}`);
    if (result.data.errorCode !== 0) throw new Error(result.data.errorMessage);
    return result.data.data;
  }
}
