import React from 'react';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { Notification } from '../components/Notification';

import { AppContext } from '../AppContext';
import { InfoModal } from '../modals/InfoModal';

export interface companyInfo {
  name: string;
  address: string;
  phone: string;
  fanpage: string;
}

const CompanyInfoPage = () => {
  const appCtx = React.useContext(AppContext);
  const [info, setInfo] = React.useState<companyInfo>({
    name: '',
    address: '',
    phone: '',
    fanpage: '',
  });

  const initialize = async () => {
    try {
      let result = await appCtx.fetch('get', '/api/companyinfo');
      if (result) {
        console.log(result);
        setInfo(result.info);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        {appCtx.isAdmin && (
          <>
            <antd.Button
              type="primary"
              onClick={() => {
                appCtx.setModal(<InfoModal onSuccess={() => initialize()} info={info} />);
              }}
            >
              修改公司資訊
            </antd.Button>
            <div style={{ marginTop: 10 }} />
          </>
        )}
      </div>
      <antd.Descriptions bordered size={'default'} column={2}>
        <antd.Descriptions.Item label="公司名稱">{info.name}</antd.Descriptions.Item>
        <antd.Descriptions.Item label="公司電話" span={2}>
          {info.phone}
        </antd.Descriptions.Item>
        <antd.Descriptions.Item label="公司粉專" span={2}>
          {info.fanpage}
        </antd.Descriptions.Item>
        <antd.Descriptions.Item label="公司地址" span={2}>
          {info.address}
        </antd.Descriptions.Item>
      </antd.Descriptions>
    </>
  );
};

export { CompanyInfoPage };
