import React from 'react';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

interface informations {
  id?: number;
  name: string;
}

const ApplyInformationPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<informations[]>([]); //coulmns data

  const [email, setEmail] = React.useState('');
  const [information, setInformation] = React.useState('');

  const [loading, setLoading] = React.useState<boolean>(true);

  const initialize = async () => {
    try {
      setLoading(true);

      let result = await appCtx.fetch('get', '/api/apply/information');
      setLoading(false);
      if (result) {
        console.log(result);
        setEmail(result.email);
        setDataSource(
          result.informations.map((item: string) => {
            return { name: item };
          }),
        );
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  const put = async () => {
    try {
      setLoading(true);
      await appCtx.fetch('put', '/api/admin/apply/information', {
        email,
        informations: dataSource.map((item) => item.name),
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<informations> = [
    {
      title: '欄位',
      align: 'center',
      dataIndex: 'name',
    },

    {
      align: 'center',
      render: (item) => (
        <antd.Button
          type="primary"
          danger
          onClick={() => setDataSource(dataSource.filter((data) => data.name !== item.name))}
        >
          刪除
        </antd.Button>
      ),
    },
  ];

  return (
    <>
      <antd.Spin tip="Loading..." spinning={loading}>
        {appCtx.isAdmin && (
          <div className="flex mb-3 space-x-4 ">
            <div>
              <antd.Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                allowClear
                placeholder={'請輸入email'}
              />
            </div>

            <div>
              <antd.Input
                value={information}
                onChange={(e) => setInformation(e.target.value)}
                allowClear
                placeholder={'請輸入審核資訊'}
              />
            </div>

            <antd.Button
              type="primary"
              onClick={() => {
                setDataSource([...dataSource, { name: information }]);
                setInformation('');
              }}
            >
              新增
            </antd.Button>

            <div className="flex-1" />
            <antd.Button type="primary" onClick={put}>
              儲存
            </antd.Button>
            <div style={{ marginTop: 10 }} />
          </div>
        )}

        <antd.Table dataSource={dataSource} columns={columns} pagination={false} />
      </antd.Spin>
    </>
  );
};

export { ApplyInformationPage };
