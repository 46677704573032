import React from 'react';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
// import { User } from './UsersPage';
import { LessonModal } from '../modals/LessonModal';
import { TraineesModal } from '../modals/TraineesModal';

import { SettingOutlined } from '@ant-design/icons';
import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

const { Option } = antd.Select;

interface refund {
  id: number; // PK
  userName: string; // 用戶名稱
  lessonName: string; // 課程名稱
  coachName: string; // 教練名稱
  orderID: string; // 訂單編號
  amount: number; // 退款金額
  status: string; // 退款狀態
  reason: string; // 退款原因
  invoiceStatus: string; // 發票處理狀態
}

const STATUS_MAP: Record<string, string> = {
  initial: '未申請',
  applied: '已申請',
  done: '已退款',
  manual: '需手動操作',
};

const INVOICE_STATUS_MAP: Record<string, string> = {
  none: '未開立發票',
  manual: '需手動操作',
  issued: '發票待處理',
  canceled: '已作廢',
  discarded: '已註銷',
  allowanced: '已開立折讓',
};

const PaidRefundPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<refund[]>([]); //coulmns data

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const [status, setStatus] = React.useState<string>('applied');

  const pageSize = 10;

  const initialize = async (page: number = currentPage, refundStatus: string = status) => {
    try {
      setLoading(true);
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (currentPage - 1)).toString());
      url.append('limit', pageSize.toString());

      let result = await appCtx.fetch(
        'get',
        `/api/admin/paidRefund/${refundStatus}?${url.toString()}`,
      );
      setLoading(false);
      if (result) {
        console.log(result);
        setTotal(result.total);
        setDataSource(result.records);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<refund> = [
    {
      title: '用戶名稱',
      align: 'center',
      dataIndex: 'userName',
    },
    {
      title: '課程名稱',
      align: 'center',
      dataIndex: 'lessonName',
    },
    {
      title: '教練名稱',
      align: 'center',
      dataIndex: 'coachName',
    },
    {
      title: '訂單編號',
      align: 'center',
      dataIndex: 'orderID',
    },
    {
      title: '退款金額',
      align: 'center',
      dataIndex: 'amount',
    },
    {
      title: '退款原因',
      align: 'center',
      dataIndex: 'reason',
    },
    {
      title: '退款狀態',
      align: 'center',
      render: (_, record) => STATUS_MAP[record.status],
    },
    {
      title: '發票處理狀態',
      align: 'center',
      render: (_, record) => INVOICE_STATUS_MAP[record.invoiceStatus],
    },
    status === 'applied'
      ? {
          align: 'center',
          render: (item) => (
            <DangerButton
              title="確認退款"
              message="請再次確認是否要退款"
              onClick={async () => {
                let data = await appCtx.fetch('patch', `/api/admin/paidRefund/${item.id}`);
                if (data) initialize();
              }}
              disabled={!appCtx.isAdmin || item.status === 'done'}
            />
          ),
        }
      : {},
  ];

  return (
    <>
      <antd.Spin tip="Loading..." spinning={loading}>
        <div className="mb-3">
          <antd.Select
            defaultValue={status}
            style={{ width: 120 }}
            onChange={(select) => {
              setStatus(select);
              initialize(currentPage, select);
            }}
          >
            <Option value="applied">未退款</Option>
            <Option value="done">已退款</Option>
          </antd.Select>
        </div>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { PaidRefundPage };
