import React from 'react';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import apiService from '../services/api-service';

export const Salesperson = ({
  onSuccess,
  name,
  phone,
  id,
}: {
  onSuccess: Function;
  name?: string;
  phone?: string;
  id?: number;
}) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    try {
      if (!id) {
        // create
        await apiService.Salesperson.create({ name: values.name, phone: values.phone });
      } else {
        // edit
        throw new Error('This function has not implemented yet');
      }
      Notification.add('success', !id ? '新增成功' : '修改成功');
      onSuccess();
    } catch (error: any) {
      Notification.add('error', error.message);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 3,
      },
    },
  };

  return (
    <antd.Form
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        name,
        phone,
      }}
    >
      <h5 className="font-weight-bold mb-4">{!id ? '新增業務人員' : '修改業務人員'}</h5>

      <antd.Form.Item name="name" label="姓名" rules={[{ required: true, message: '請輸入姓名' }]}>
        <antd.Input placeholder="業務人員姓名" />
      </antd.Form.Item>

      <antd.Form.Item name="phone" label="電話" rules={[{ required: true, message: '請輸入電話' }]}>
        <antd.Input placeholder="業務人員電話" />
      </antd.Form.Item>

      <antd.Form.Item
        label="確認新增業務人員"
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('請勾選確認新增業務人員')),
          },
        ]}
      >
        <antd.Checkbox />
      </antd.Form.Item>

      <antd.Form.Item className="text-center" {...tailFormItemLayout}>
        <PrimaryButton title={!id ? '新增' : '修改'} htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};
