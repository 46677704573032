import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import apiService from '../services/api-service';
import { Salesperson } from '../modals/Salesperson';
import { Button, DatePicker, Spin, Table } from 'antd';
import moment from 'moment';

interface Salesperson {
  name: string;
  phone: string;
  salesCode: string;
  usersCount: number;
}

const DATE_FORMAT = 'YYYY-MM';

function getDefaultDateRange() {
  return {
    start: moment().add(-1, 'month').startOf('month').startOf('day'),
    end: moment().endOf('month').endOf('day'),
  };
}

const SalespersonPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<Salesperson[]>([]); //coulmns data
  const [rangeChanged, setRangeChanged] = React.useState<boolean>(false);
  const [summaryRange, setSummaryRange] = React.useState<{
    start: moment.Moment;
    end: moment.Moment;
  }>(getDefaultDateRange());

  // prettier-ignore
  const summaryRangeText = `${summaryRange.start.format(DATE_FORMAT)} ~ ${summaryRange.end.format(DATE_FORMAT)}`

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      const data = await apiService.Salesperson.fetch({
        offset: (page - 1) * pageSize,
        limit: pageSize,
        summaryStart: summaryRange.start.toISOString(),
        summaryEnd: summaryRange.end.toISOString(),
      });
      setLoading(false);

      if (data) {
        setDataSource(data.data);
        setTotal(data.total);
        setCurrentPage(page);
      }
    } catch (error: any) {
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<Salesperson> = [
    {
      title: '業務員姓名',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '業務代碼',
      align: 'center',
      dataIndex: 'salesCode',
    },
    {
      title: '電話',
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: '下載註冊數',
      align: 'center',
      dataIndex: 'usersCount',
    },
    {
      title: `用戶完成課程數 (${summaryRangeText})`,
      align: 'center',
      dataIndex: 'totalFinishLessons',
    },
  ];

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        {appCtx.isAdmin && (
          <>
            <Button
              type="primary"
              onClick={() => {
                appCtx.setModal(<Salesperson onSuccess={initialize} />);
              }}
            >
              新增業務人員
            </Button>
            <div style={{ marginTop: 10 }} />
          </>
        )}
        <div className="ml-auto">
          統計區間
          <DatePicker.RangePicker
            className={'ml-1'}
            picker={'month'}
            defaultValue={[summaryRange.start, summaryRange.end]}
            allowClear={false}
            onChange={(range) => {
              if (range?.[0] && range?.[1])
                setSummaryRange({
                  start: range[0].startOf('month').startOf('day'),
                  end: range[1].endOf('month').endOf('day'),
                });
              setRangeChanged(true);
            }}
            onOpenChange={(open) => {
              if (!open && rangeChanged) {
                initialize(currentPage);
                setRangeChanged(false);
              }
            }}
          />
        </div>
      </div>
      <Spin tip="Loading..." spinning={loading}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </Spin>
    </>
  );
};

export { SalespersonPage };
