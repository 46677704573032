import React from 'react';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
// import { User } from './UsersPage';
import { LessonModal } from '../modals/LessonModal';
import { EditLessonTime } from '../modals/EditLessonTime';
import { EditLessonLocation } from '../modals/EditLessonLocation';

import { TraineesModal } from '../modals/TraineesModal';

import { SettingOutlined } from '@ant-design/icons';
import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

const { Option } = antd.Select;

interface lesson {
  id: number;
  name: string;
  counties: string;
  district: string;
  section: string;
  durationStart: string;
  durationEnd: string;
  introduction: string;
  user: User;
  numType: string;
  classType: string;
}

const LessonsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<lesson[]>([]); //coulmns data

  const [input, setInput] = React.useState<string>('');
  const [select, setSelect] = React.useState<string>('1');
  const [selectDelete, setSelectDelete] = React.useState<string>('2');
  const [durationStart, setDurationStart] = React.useState<string>('');
  const [durationEnd, setDurationEnd] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage, selectDel: string = selectDelete) => {
    setLoading(true);
    try {
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (currentPage - 1)).toString());
      url.append('limit', pageSize.toString());
      if (durationStart) url.append('durationStart', durationStart);
      if (durationEnd) url.append('durationEnd', durationEnd);

      if (input !== '') {
        if (select === '1') {
          url.append('lessonName', input);
        } else {
          url.append('coachName', input);
        }
      }
      switch (selectDel) {
        case '1':
          break;
        case '2':
          url.append('status', 'normal');
          break;
        case '3':
          url.append('status', 'delete');
          break;
        default:
          url.append('status', 'permanentlyDeleted');
          break;
      }

      let result = await appCtx.fetch('get', '/api/admin/lessons?' + url.toString());
      setLoading(false);
      if (result) {
        console.log(result);
        setTotal(result.num);
        setDataSource(result.lessons);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<lesson> = [
    {
      title: '課程名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '教練',
      align: 'center',
      render: (item) => <>{`${item.user.name}`}</>,
    },
    {
      title: '課程狀態',
      align: 'center',
      render: (item) => (
        <antd.Tag
          color={item.status === 'normal' ? 'blue' : item.status === 'delete' ? 'cyan' : 'red'}
        >
          {item.status === 'normal'
            ? '用戶可見'
            : item.status === 'delete'
            ? '暫時刪除'
            : '永久刪除'}
        </antd.Tag>
      ),
    },
    {
      title: '起始日期',
      align: 'center',
      render: (item) => <>{dayjs(item.durationStart).format('YYYY-MM-DD')}</>,
    },
    {
      title: '結束日期',
      align: 'center',
      render: (item) => <>{dayjs(item.durationEnd).format('YYYY-MM-DD')}</>,
    },
    {
      title: '地點',
      align: 'center',
      render: (item) => <>{`${item.counties}${item.district}${item.section}`}</>,
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <antd.Button type="primary" onClick={() => appCtx.setModal(<LessonModal lesson={item} />)}>
          查看詳細
        </antd.Button>
      ),
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <antd.Button
          type="primary"
          onClick={() =>
            appCtx.setModal(
              <EditLessonTime
                onSuccess={initialize}
                id={item.id}
                name={item.name}
                durationStart={item.durationStart}
                durationEnd={item.durationEnd}
                classTimes={item.ClassTimes}
              />,
            )
          }
        >
          編輯課程時間
        </antd.Button>
      ),
    },
    {
      title: '',
      align: 'center',
      render: (item) => (
        <antd.Button
          type="primary"
          onClick={() =>
            appCtx.setModal(
              <EditLessonLocation
                onSuccess={initialize}
                id={item.id}
                name={item.name}
                counties={item.counties}
                district={item.district}
                section={item.section}
              />,
            )
          }
        >
          編輯課程地點
        </antd.Button>
      ),
    },
    {
      align: 'center',
      render: (item) => (
        <DangerButton
          title={item.LessonRegists && item.LessonRegists.length > 0 ? '已有用戶預約' : '刪除課程'}
          message="確認刪除課程"
          onClick={async () => {
            let data = await appCtx.fetch('delete', `/api/admin/lesson/${item.id}`);
            if (data) initialize();
          }}
          disabled={
            item.LessonRegists && item.LessonRegists.length > 0
              ? true
              : !appCtx.isAdmin ||
                item.status === 'permanentlyDeleted' ||
                dayjs() > dayjs(item.durationEnd)
          }
        />
      ),
    },
  ];

  const SelectAfter = (
    <antd.Select
      defaultValue={select}
      style={{ width: 120 }}
      onChange={(select) => setSelect(select)}
    >
      <Option value="1">課程名稱</Option>
      <Option value="2">教練名稱</Option>
    </antd.Select>
  );

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        <div className="w-3/12">
          <antd.Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            allowClear
            addonBefore={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Select
          defaultValue={selectDelete}
          style={{ width: 120 }}
          onChange={(select) => {
            setSelectDelete(select);
            initialize(currentPage, select);
          }}
        >
          <Option value="1">全部課程</Option>
          <Option value="2">僅用戶可見</Option>
          {/* <Option value="3">僅暫時刪除</Option> */}
          <Option value="4">僅已刪除</Option>
        </antd.Select>
        <antd.DatePicker
          style={{ width: 150 }}
          onChange={(_, dateString) => setDurationStart(dateString)}
          placeholder="起始日期(選填)"
        />
        <antd.DatePicker
          style={{ width: 150 }}
          onChange={(_, dateString) => setDurationEnd(dateString)}
          placeholder="結束日期(選填)"
        />
        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { LessonsPage };
