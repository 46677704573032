import axios, { AxiosRequestConfig } from 'axios';
import { AdminToken } from '../adminToken';

export class ApiClient {
  private axiosInstance = axios.create();
  private config?: AxiosRequestConfig = undefined;

  interceptors = {
    request: {
      use: this.axiosInstance.interceptors.request.use.bind(
        this.axiosInstance.interceptors.request,
      ),
      eject: this.axiosInstance.interceptors.request.eject.bind(
        this.axiosInstance.interceptors.request,
      ),
    },
    response: {
      use: this.axiosInstance.interceptors.response.use.bind(
        this.axiosInstance.interceptors.response,
      ),
      eject: this.axiosInstance.interceptors.response.eject.bind(
        this.axiosInstance.interceptors.response,
      ),
    },
  };

  constructor(config?: AxiosRequestConfig) {
    config && this.setConfig(config);
  }

  setConfig(config: AxiosRequestConfig): void {
    this.axiosInstance.defaults = this.config = { ...this.axiosInstance, ...config };
  }

  get(url: string, params?: any, config?: AxiosRequestConfig) {
    return this.request({ method: 'GET', url: url, params: params, ...config });
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request({ method: 'POST', url: url, data: data, ...config });
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request({ method: 'PUT', url: url, data: data, ...config });
  }

  patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request({ method: 'PATCH', url: url, data: data, ...config });
  }

  delete(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request({ method: 'DELETE', url: url, data: data, ...config });
  }

  request(config: AxiosRequestConfig) {
    config.headers = { ...config.headers, ...{ authorization: AdminToken.get() } };
    const response = this.axiosInstance.request({ ...this.config, ...config });
    AdminToken.checkExpire();
    return response;
  }
}
