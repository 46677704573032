import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Button, DatePicker, Form, Table } from 'antd';
import * as cookie from 'js-cookie';
import moment from 'moment';

import { AppContext } from '../AppContext';
import * as querystring from "querystring";
import FileSaver from "file-saver";
import axios from "axios";
import {Notification} from "../components/Notification";
import { AdminToken } from '../services/adminToken';

function downloadReport(url: string): void {
  axios
    .get(url, {
      responseType: 'blob',
      headers: {
        authorization: AdminToken.get(),
      },
    })
    .then((value) => {
      const contentDisposition = value.headers['content-disposition'];
      FileSaver.saveAs(value.data, getFilenameFromContentDisposition(contentDisposition));
    })
    .catch((reason) => {
      console.log(reason);
      Notification.add('error', '下載失敗: ' + reason.message);
    });
}

function getFilenameFromContentDisposition(disposition: string): string | undefined {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return decodeURIComponent(matches[1].replace(/['"]/g, ''));
    }
    return undefined;
  }
}

interface ReportItem {
  title: string;
  path: string;
  key?: any;
  options: React.ReactNode;
  parser?: (data: any) => any;
}

const ReportItems: ReportItem[] = [
  {
    title: '教練課程統計',
    path: '/api/admin/report/coach-statistics/download',
    options: <>
      <Form.Item label={"時間區間"} name={"duration"}>
        <DatePicker.RangePicker picker={'month'}/>
      </Form.Item>
    </>,
    parser: data => {
      if (!data.duration) return null;
      return {
        start: (data.duration[0] as moment.Moment)
          .startOf('month').startOf('day').toISOString(),
        end: (data.duration[1] as moment.Moment)
          .endOf('month').endOf('day').toISOString()
      };
    }
  }
];

const ReportsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [options, setOptions] = React.useState<any>({});

  const reportItems = React.useMemo(() => {
    return ReportItems.map((item, idx) => {
      item.options = <Form onValuesChange={data => {
        console.log(data);
        setOptions((prevState: any) => ({ ...prevState, [item.title]: data }));
      }}>
        {item.options}
      </Form>
      item.key = idx;
      return item;
    });
  }, [setOptions]);


  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
  }, []);

  const columns: ColumnsType<ReportItem> = [
    {
      title: '項目',
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '選項',
      align: 'center',
      dataIndex: 'options'
    },
    {
      title: '下載',
      align: 'center',
      render: (item: ReportItem) =>
        <Button type={"primary"} onClick={() => {
          const data = options[item.title];
          const query = querystring.encode(data ? item.parser?.(data) ?? data : null);
          const link = `${item.path}?${query}`;
          downloadReport(link);
        }}>
          下載
        </Button>,
    }
  ];

  return (
    <>
      <Table
        dataSource={reportItems}
        columns={columns}
      />
    </>
  );
};

export { ReportsPage };
