import { ApiClient } from './apiClient';
import { Coupon } from './coupon';
import { Salesperson } from './salesperson';

class ApiService {
  $client = new ApiClient();
  Salesperson = new Salesperson(this.$client);
  Coupon = new Coupon(this.$client);

  $setConfig(config: Parameters<ApiClient['setConfig']>[0]): ReturnType<ApiClient['setConfig']> {
    return this.$client.setConfig(config);
  }

  $newInstance() {
    return new ApiService();
  }
}

export type { SalespersonFetchOptions, SalespersonCreateOptions } from './salesperson';
export type { CouponFetchOptions } from './coupon';

const apiService = new ApiService();
export default apiService;
