import React from 'react';
import * as antd from 'antd';
import QRCode from 'qrcode.react';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const QRcode = ({ id, URL }: { id: string; URL?: string }) => {
  const appCtx = React.useContext(AppContext);
  const [url, setUrl] = React.useState<string>('');

  const init = async () => {
    const data = await appCtx.fetch('get', '/api/admin/qrcode/' + id);
    if (data) setUrl(data.QRcode);
  };

  React.useEffect(() => {
    if (URL) {
      setUrl(URL);
    } else {
      init();
    }
  }, []);

  return (
    <antd.Spin tip="Loading..." spinning={url === ''}>
      <antd.Card
        hoverable
        cover={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode value={url} />
          </div>
        }
      >
        <antd.Card.Meta
          title="已為您所創建的帳號啟動一次性驗證碼驗證。"
          description="您必須要將此 QR Code 提供給創建的使用者，並協助新使用者使用應用程式 Google Authenticator 來登入帳號。如果您因為任何原因無法使用一次性驗證碼 (例如，遺失手機)，您可以向最高權限管理員請求協助。"
        />
      </antd.Card>
    </antd.Spin>
  );
};
