import React from 'react';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const isEmail = (strEmail: string) => {
  if (strEmail.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/) != -1)
    return true;
  else return false;
};

const AddUser = ({ onSuccess }: { onSuccess: Function }) => {
  const appCtx = React.useContext(AppContext);
  const [birth, setBirth] = React.useState<string>('');
  const [gender, setGender] = React.useState<string>('male');

  const [checkName, setCheckName] = React.useState<boolean>(true);

  React.useEffect(() => {}, []);

  const checkUserName = async (name: string) => {
    console.log(`checkName: ${checkName}`);
    if (name) {
      let result = await appCtx.fetch('get', `/api/username/${name}`);
      if (result) {
        setCheckName(!result.duplicate);
      }
    }
  };

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    if (!isEmail(values.email)) {
      Notification.add('error', 'email格式錯誤');
      return;
    }

    const data = await appCtx.fetch('post', `/api/admin/coach`, {
      name: values.name,
      realName: values.realName,
      gender,
      phone: `${values.phone}`,
      email: values.email,
      birth,
      password: values.password,
    });

    if (data) {
      Notification.add('success', '新增成功');
      onSuccess();
    }
  };

  return (
    <antd.Form onFinish={onFinish} initialValues={{ gender: 'male' }}>
      <h5 className="font-weight-bold mb-4">新增教練</h5>

      <antd.Form.Item
        name="name"
        hasFeedback
        validateStatus={checkName ? '' : 'error'}
        rules={[{ required: true, message: '請輸入教練名稱!' }]}
        help={!checkName && '名稱重複'}
      >
        <antd.Input
          prefix={<i className="fa fa-user-circle-o" />}
          onChange={(e) => checkUserName(e.target.value)}
          placeholder="請輸入教練名稱"
        />
      </antd.Form.Item>

      <antd.Form.Item
        name="realName"
        hasFeedback
        rules={[{ required: true, message: '請輸入教練真實姓名!' }]}
      >
        <antd.Input
          prefix={<i className="fa fa-user-circle-o" />}
          placeholder="請輸入教練真實姓名"
        />
      </antd.Form.Item>

      <antd.Form.Item
        name="email"
        rules={[{ required: true, message: '請輸入正確的email格式!', type: 'email' }]}
      >
        <antd.Input prefix={<i className="fa fa-envelope" />} placeholder="請輸入email" />
      </antd.Form.Item>

      <antd.Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: '密碼不可以空白!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value.length >= 6) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密碼長度不能小於6'));
            },
          }),
        ]}
      >
        <antd.Input.Password prefix={<i className="fa fa-lock" />} placeholder="請輸入密碼" />
      </antd.Form.Item>

      <antd.Form.Item
        name="checkPassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: '密碼不可以空白!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密碼必須與上面相同'));
            },
          }),
        ]}
      >
        <antd.Input.Password prefix={<i className="fa fa-lock" />} placeholder="請再次輸入密碼" />
      </antd.Form.Item>

      <antd.Form.Item
        name="phone"
        rules={[
          { required: true, message: '電話號碼不可以空白!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value.length === 10) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('電話號碼長度錯誤'));
            },
          }),
        ]}
      >
        <antd.Input prefix={<i className="fa fa-mobile" />} placeholder="請輸入電話號碼" />
      </antd.Form.Item>

      <div className="flex gap-4 ">
        <antd.Form.Item label="性別" name="gender">
          <antd.Select style={{ width: 120 }} onChange={(value: any) => setGender(value)}>
            <antd.Select.Option value="male">男性</antd.Select.Option>
            <antd.Select.Option value="female">女性</antd.Select.Option>
          </antd.Select>
        </antd.Form.Item>

        <antd.Form.Item
          label="出生日期"
          name="birth"
          rules={[{ required: true, message: '請填入出生日期' }]}
        >
          <antd.DatePicker onChange={(_, dateString) => setBirth(dateString)} />
        </antd.Form.Item>
      </div>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="新增" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};

export { AddUser };
