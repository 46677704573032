import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { companyInfo } from '../pages/CompanyInfoPage';

interface infoProps {
  info: companyInfo;
  onSuccess: Function;
}

const InfoModal = ({ info, onSuccess }: infoProps) => {
  const appCtx = React.useContext(AppContext);

  React.useEffect(() => {}, []);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    const data = await appCtx.fetch('patch', `/api/admin/companyinfo`, {
      name: values.name,
      address: values.address,
      phone: values.phone,
      fanpage: values.fanpage,
    });

    if (data) {
      Notification.add('success', '修改成功');
      onSuccess();
    }
  };

  return (
    <antd.Form
      onFinish={onFinish}
      initialValues={{
        name: info.name,
        address: info.address,
        phone: info.phone,
        fanpage: info.fanpage,
      }}
    >
      <h5 className="font-weight-bold mb-4">修改公司資訊</h5>

      <antd.Form.Item name="name" rules={[{ required: true, message: '公司名稱不可空白' }]}>
        <antd.Input prefix={<i className="fa fa-address-card-o" />} placeholder="請輸入公司名稱" />
      </antd.Form.Item>

      <antd.Form.Item name="phone" rules={[{ required: true, message: '公司電話不可空白' }]}>
        <antd.Input prefix={<i className="fa fa-phone-square" />} placeholder="請輸入公司電話" />
      </antd.Form.Item>

      <antd.Form.Item name="fanpage" rules={[{ required: true, message: '公司粉專不可空白' }]}>
        <antd.Input
          prefix={<i className="fa fa-facebook-official" />}
          placeholder="請輸入公司粉專"
        />
      </antd.Form.Item>

      <antd.Form.Item name="address" rules={[{ required: true, message: '公司地址不可空白!' }]}>
        <antd.Input prefix={<i className="fa fa-map-marker" />} placeholder="請輸入公司地址" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="修改" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};

export { InfoModal };
