import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext';

import { ApolloClient, InMemoryCache, NormalizedCacheObject, DefaultOptions } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';

import 'tailwindcss/tailwind.css';
import './index.css';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import moment from 'moment';
import 'moment/locale/zh-tw';
import { Notification } from './components/Notification';
import axios from 'axios';
import apiService from './services/api-service';
import { API_DOMAIN } from './config';

moment.locale('zh-tw');

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const apolloClient = new ApolloClient({
  uri: API_DOMAIN + '/graphql',
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

// 初始化設定 api 服務
axios.defaults.baseURL = API_DOMAIN;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

apiService.$setConfig({
  baseURL: API_DOMAIN,
  withCredentials: true,
});
apiService.$client.interceptors.response.use(
  (response) => {
    console.log(response.data);

    if (response.data.errorCode === 9999) {
      window.location.href = '/#/login';
      return response;
    }

    if (response.data.errorCode !== 0) {
      throw new Error(response.data.errorMessage);
    }

    return response;
  },
  (error) => {
    Notification.add('error', error.message);
    console.error(error);
  },
);

ReactDOM.render(
  <AppProvider>
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={zhTW}>
        <App />
      </ConfigProvider>
    </ApolloProvider>
  </AppProvider>,
  document.getElementById('root'),
);

reportWebVitals();
