import React from 'react';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
// import { User } from './UsersPage';
import { LessonModal } from '../modals/LessonModal';
import { TraineesModal } from '../modals/TraineesModal';

import { SettingOutlined } from '@ant-design/icons';
import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

const { Option } = antd.Select;

interface contactus {
  id: number;
  name: string;
  email: string;
  phone: string;
  content: string;
  createdAt: string;
}

const ContactUsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<contactus[]>([]); //coulmns data

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (currentPage - 1)).toString());
      url.append('limit', pageSize.toString());

      let result = await appCtx.fetch('get', '/api/admin/contactus?' + url.toString());
      setLoading(false);
      if (result) {
        console.log(result);
        setTotal(result.total);
        setDataSource(result.records);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<contactus> = [
    {
      title: '名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: 'e-mail',
      align: 'center',
      dataIndex: 'email',
    },
    {
      title: '電話',
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: '留言內容',
      align: 'center',
      dataIndex: 'content',
    },
    {
      title: '留言日期',
      align: 'center',
      render: (ite, record) => <>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm')}</>,
    },
    {
      align: 'center',
      render: (item) => (
        <DangerButton
          title="刪除留言"
          message="確認刪除留言"
          onClick={async () => {
            let data = await appCtx.fetch('delete', `/api/admin/contactus/${item.id}`);
            if (data) {
              initialize();
            }
          }}
          disabled={!appCtx.isAdmin || item.status === 'permanentlyDeleted'}
        />
      ),
    },
  ];

  return (
    <>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { ContactUsPage };
