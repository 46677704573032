import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import * as cookie from 'js-cookie';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
import { EditAgent } from '../modals/EditAgent';
import { AddAgent } from '../modals/AddAgent';
import { QRcode } from '../modals/QRcode';

const { Option } = antd.Select;

interface Agent {
  key: string;
  account: string;
  admin: boolean;
}

const AgentsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<Agent[]>([]); //coulmns data

  const [agentName, setAgentName] = React.useState<string>('');
  const [selectAdmin, setSelectAdmin] = React.useState<string>('1');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let admin = '';
      if (selectAdmin === '2') {
        admin = 'true';
      } else if (selectAdmin === '3') {
        admin = 'false';
      }

      const data = await appCtx.fetch(
        'get',
        `/api/admin/agents?offset=${
          (page - 1) * pageSize
        }&limit=${pageSize}&name=${agentName}&admin=${admin}`,
      );
      setLoading(false);

      if (data) {
        setDataSource(data.administrators);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, [selectAdmin]);

  const onAuthChange = async (checked: boolean, account: string) => {
    if (!appCtx.isAdmin) {
      Notification.add('error', '帳號權限不足');
      return;
    }
    try {
      let data = await appCtx.fetch('patch', `/api/admin/agent/${account}`, {
        admin: checked,
      });
      if (data) {
        initialize();
      }
    } catch (error: any) {
      Notification.add('error', '請求失敗');
    }
  };

  const columns: ColumnsType<Agent> = [
    {
      title: '帳號',
      dataIndex: 'account',
      align: 'center',
    },
    {
      title: '管理員權限',
      align: 'center',
      render: (item) => (
        <antd.Switch
          disabled={
            item.account === 'admin' //admin不可被任何人修改
              ? true
              : appCtx.account !== 'admin'
          }
          checked={item.admin}
          onChange={(checked) => onAuthChange(checked, item.account)}
        />
      ),
    },
    {
      key: 'secret',
      align: 'center',
      render: (item) => (
        <antd.Button
          disabled={appCtx.account !== 'admin'}
          type="primary"
          onClick={() => {
            appCtx.setModal(<QRcode id={item.account} />);
          }}
        >
          查看QRcode
        </antd.Button>
      ),
    },
    {
      align: 'center',
      render: (item) => (
        <antd.Button
          disabled={
            appCtx.account === 'admin' // admin可以修改任何人
              ? false
              : !appCtx.isAdmin && item.account !== appCtx.account // 一般人不可修改任何人,除了自己
              ? true
              : item.account === 'admin' //admin不可被任何人修改
              ? true
              : !item.admin // 一般人可以被任何人修改
              ? false
              : item.account !== appCtx.account && appCtx.account !== 'admin' // 非admin只能修改自己
          }
          danger
          onClick={() =>
            appCtx.setModal(<EditAgent onSuccess={initialize} account={item.account} />)
          }
        >
          修改密碼
        </antd.Button>
      ),
    },
    {
      align: 'center',
      render: (item) => (
        <DangerButton
          title="刪除帳號"
          message="確認刪除帳號"
          onClick={async () => {
            let data = await appCtx.fetch('delete', `/api/admin/agent/${item.account}`);
            if (data) {
              initialize();
            }
          }}
          disabled={
            item.account === 'admin' //admin不可被任何人修改
              ? true
              : appCtx.account === 'admin' // admin可以修改任何人
              ? false
              : !appCtx.isAdmin // 一般人不可修改任何人
              ? true
              : item.admin // 一般人可以被任何人修改
          }
        />
      ),
    },
  ];

  const SelectAfter = (
    <antd.Select
      defaultValue={selectAdmin}
      style={{ width: 100 }}
      onChange={(select) => setSelectAdmin(select)}
    >
      <Option value="1">所有身份</Option>
      <Option value="2">僅管理員</Option>
      <Option value="3">非管理員</Option>
    </antd.Select>
  );

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        {appCtx.isAdmin && (
          <>
            <antd.Button
              type="primary"
              onClick={() => {
                appCtx.setModal(<AddAgent onSuccess={initialize} />);
              }}
            >
              新增帳號
            </antd.Button>
            <div style={{ marginTop: 10 }} />
          </>
        )}
        <div>
          <antd.Input
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
            allowClear
            addonAfter={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={
            appCtx.account === 'admin' ? columns : columns.filter((item) => item.key !== 'secret')
          }
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { AgentsPage };
