import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import * as cookie from 'js-cookie';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
import { EditAgent } from '../modals/EditAgent';
import { AddAgent } from '../modals/AddAgent';
import { LessonPrice } from '../modals/LessonPriceModal';

const { Option } = antd.Select;

interface Agent {
  key: string;
  account: string;
  admin: boolean;
}

const LessonPricePage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<Agent[]>([]); //coulmns data

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let data = await appCtx.fetch(
        'get',
        `/api/admin/lessonprice?offset=${(page - 1) * pageSize}&limit=${pageSize}`,
      );
      setLoading(false);
      console.log(data);

      if (data) {
        setDataSource(data.data);
        setCurrentPage(page);
      }
    } catch (error: any) {
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    const user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<Agent> = [
    {
      title: '活動名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '起始日期',
      align: 'center',
      render: (item) => <>{dayjs(item.durationStart).format('YYYY-MM-DD')}</>,
    },
    {
      title: '結束日期',
      align: 'center',
      render: (item) => <>{dayjs(item.durationEnd).format('YYYY-MM-DD')}</>,
    },
    {
      title: '價格',
      align: 'center',
      dataIndex: 'price',
    },
  ];

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        {appCtx.isAdmin && (
          <>
            <antd.Button
              type="primary"
              onClick={() => {
                appCtx.setModal(<LessonPrice onSuccess={initialize} />);
              }}
            >
              新增活動
            </antd.Button>
            <div style={{ marginTop: 10 }} />
          </>
        )}
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { LessonPricePage };
