import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import moment from 'moment';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const { RangePicker } = antd.DatePicker;

interface EditRegistTimeProps {
  id: number;
  name: string;
  timeStart: Date;
  timeEnd: Date;
  onSuccess: Function;
}

export const EditRegistTime = ({
  onSuccess,
  name,
  timeStart,
  timeEnd,
  id,
}: EditRegistTimeProps) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);
    const timeStart = moment(values.timeDuration[0])
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toISOString();
    const timeEnd = moment(values.timeDuration[1])
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toISOString();

    const data = await appCtx.fetch('patch', `/api/admin/lessonregist/${id}`, {
      timeStart: timeStart,
      timeEnd: timeEnd,
    });

    if (data) {
      Notification.add('success', '修改成功');
      onSuccess();
    }
  };

  return (
    <antd.Form
      onFinish={onFinish}
      initialValues={{ timeDuration: [moment(timeStart), moment(timeEnd)] }}
    >
      <h5 className="font-weight-bold mb-4">修改{name}預約時間</h5>

      <antd.Form.Item
        name="timeDuration"
        label="課程時間"
        rules={[{ required: true, message: '請選擇課程時間!' }]}
      >
        <RangePicker showTime format="YYYY-MM-DD HH:00" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="修改" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};
