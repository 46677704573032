import React from 'react';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { QRcode } from './QRcode';

const AddAgent = ({ onSuccess }: { onSuccess: Function }) => {
  const appCtx = React.useContext(AppContext);

  React.useEffect(() => {}, []);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    const data = await appCtx.fetch('post', `/api/admin/agent`, {
      id: values.account,
      password: values.password,
    });

    if (data) {
      appCtx.setModal(<QRcode id={values.account} URL={data.URL} />);
      Notification.add('success', '新增成功');
      onSuccess();
    }
  };

  return (
    <antd.Form onFinish={onFinish}>
      <h5 className="font-weight-bold mb-4">新增帳號</h5>

      <antd.Form.Item name="account" rules={[{ required: true, message: '請輸入帳號!' }]}>
        <antd.Input prefix={<i className="fa fa-user" />} placeholder="請輸入帳號" />
      </antd.Form.Item>

      <antd.Form.Item name="password" rules={[{ required: true, message: '密碼不可以空白!' }]}>
        <antd.Input.Password prefix={<i className="fa fa-lock" />} placeholder="請輸入密碼" />
      </antd.Form.Item>

      <antd.Form.Item
        name="checkPassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: '密碼不可以空白!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密碼必須相同'));
            },
          }),
        ]}
      >
        <antd.Input.Password prefix={<i className="fa fa-lock" />} placeholder="請再次輸入密碼" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="新增" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};

export { AddAgent };
