import React from 'react';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import { gql } from '@apollo/client';

import { AppContext } from '../AppContext';
import { DangerButton } from '../components/DangerButton';
import { Notification } from '../components/Notification';
import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

interface evaluation {
  id: number;
  star: number;
  // userId: string;
  comment: string;
  coach: User;
  user: User;
}

const EvaluationsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<evaluation[]>([]); //coulmns data

  const [user, setUser] = React.useState<string>('');
  const [coach, setCoach] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let result = await appCtx.apolloClient.query({
        query: gql`
          query evaluations($limit: Int, $offset: Int, $coachName: String, $userName: String) {
            evaluations(
              limit: $limit
              offset: $offset
              coachName: $coachName
              userName: $userName
            ) {
              id
              star
              comment
              coach {
                id
                name
                introduction
                gender
              }
              user {
                id
                name
              }
            }
            totalEvaluation
          }
        `,
        variables: {
          offset: (currentPage - 1) * pageSize,
          limit: pageSize,
          userName: user,
          coachName: coach,
        },
      });
      setLoading(false);
      // console.log('apollo result:' + JSON.stringify(result));
      console.log(result.data);
      setTotal(result.data.totalEvaluation);
      setDataSource(result.data.evaluations);
      setCurrentPage(page);
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, [currentPage]);

  const columns: ColumnsType<evaluation> = [
    {
      title: '評價者名稱',
      align: 'center',
      render: (item) => <>{`${item.user.name}`}</>,
    },
    {
      title: '評價分數',
      align: 'center',
      dataIndex: 'star',
    },
    {
      title: '評價內容',
      align: 'center',
      dataIndex: 'comment',
    },
    {
      title: '教練',
      align: 'center',
      render: (item) => <>{`${item.coach.name}`}</>,
    },
    {
      align: 'center',
      render: (item) => (
        <DangerButton
          title="刪除評價"
          message="確認刪除評價"
          onClick={async () => {
            let data = await appCtx.fetch('delete', `/api/admin/evaluation/${item.id}`);
            if (data) {
              initialize();
            }
          }}
          disabled={!appCtx.isAdmin}
        />
      ),
    },
  ];

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        <div>
          <antd.Input
            value={user}
            onChange={(e) => setUser(e.target.value)}
            allowClear
            placeholder={`請輸入評價者名稱(選填)`}
          />
        </div>
        <div>
          <antd.Input
            value={coach}
            onChange={(e) => setCoach(e.target.value)}
            allowClear
            placeholder={`請輸入教練名稱(選填)`}
          />
        </div>

        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { EvaluationsPage };
