import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Time: any;
};

export type Query = {
  __typename?: 'Query';
  users?: Maybe<Array<User>>;
  totalUser?: Maybe<Scalars['Int']>;
  lessons?: Maybe<Array<Lesson>>;
  totalLesson?: Maybe<Scalars['Int']>;
  evaluations?: Maybe<Array<Evaluation>>;
  totalEvaluation?: Maybe<Scalars['Int']>;
  regist?: Maybe<Array<Maybe<Regist>>>;
};

export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disable?: Maybe<Scalars['Boolean']>;
  identity?: Maybe<Scalars['String']>;
};

export type QueryTotalUserArgs = {
  name?: Maybe<Scalars['String']>;
  disable?: Maybe<Scalars['Boolean']>;
  identity?: Maybe<Scalars['String']>;
};

export type QueryLessonsArgs = {
  id?: Maybe<Scalars['ID']>;
  coachName?: Maybe<Scalars['String']>;
  lesson?: Maybe<Scalars['String']>;
  counties?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  durationStart?: Maybe<Scalars['Date']>;
  durationEnd?: Maybe<Scalars['Date']>;
  timeStart?: Maybe<Scalars['Date']>;
  timeEnd?: Maybe<Scalars['Date']>;
  classType?: Maybe<Array<Maybe<Scalars['String']>>>;
  numType?: Maybe<NumType>;
  gender?: Maybe<Gender>;
  coachID?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryTotalLessonArgs = {
  id?: Maybe<Scalars['ID']>;
  coachName?: Maybe<Scalars['String']>;
  lesson?: Maybe<Scalars['String']>;
  counties?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  durationStart?: Maybe<Scalars['Date']>;
  durationEnd?: Maybe<Scalars['Date']>;
  priceUpperLimit?: Maybe<Scalars['Int']>;
  priceLowerLimit?: Maybe<Scalars['Int']>;
  classType?: Maybe<Array<Maybe<Scalars['String']>>>;
  numType?: Maybe<NumType>;
  gender?: Maybe<Gender>;
  coachID?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
};

export type QueryEvaluationsArgs = {
  userID?: Maybe<Scalars['String']>;
  coachID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryTotalEvaluationArgs = {
  userID?: Maybe<Scalars['String']>;
  coachID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
};

export type QueryRegistArgs = {
  id: Scalars['ID'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  realName?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  disable?: Maybe<Scalars['Boolean']>;
  headshot?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Array<Maybe<Evaluation>>>;
  rating?: Maybe<Scalars['Float']>;
  evaluationNum?: Maybe<Scalars['Int']>;
  lessonCount?: Maybe<Scalars['Int']>;
  lastSignIn?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  identity?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['String']>;
  ApplyCoach?: Maybe<Scalars['Int']>;
};

export type Lesson = {
  __typename?: 'Lesson';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<Scalars['String']>>>;
  counties?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  durationStart?: Maybe<Scalars['String']>;
  durationEnd?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  numType?: Maybe<NumType>;
  classType?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  ClassTimes?: Maybe<Array<Maybe<ClassTime>>>;
};

export type ClassTime = {
  __typename?: 'ClassTime';
  dayOfWeek?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
};

export type Evaluation = {
  __typename?: 'Evaluation';
  id: Scalars['ID'];
  star?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  coach?: Maybe<User>;
  user?: Maybe<User>;
};

export type Regist = {
  __typename?: 'Regist';
  id: Scalars['ID'];
  date?: Maybe<Scalars['Date']>;
  timeStart?: Maybe<Scalars['Time']>;
  timeEnd?: Maybe<Scalars['Time']>;
  user?: Maybe<User>;
};

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum NumType {
  Group = 'group',
  Single = 'single',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum Status {
  Normal = 'normal',
  Delete = 'delete',
  PermanentlyDeleted = 'permanentlyDeleted',
}

export type UsersQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  disable?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  identity?: Maybe<Scalars['String']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  totalUser?: number | null | undefined;
  users?:
    | Array<{
        __typename?: 'User';
        id: string;
        name: string;
        realName?: string | null | undefined;
        introduction?: string | null | undefined;
        gender?: string | null | undefined;
        disable?: boolean | null | undefined;
        headshot?: string | null | undefined;
        rating?: number | null | undefined;
        evaluationNum?: number | null | undefined;
        lastSignIn?: any | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        identity?: string | null | undefined;
        birth?: string | null | undefined;
        ApplyCoach?: number | null | undefined;
      }>
    | null
    | undefined;
};

export const UsersDocument = gql`
  query users($name: String, $disable: Boolean, $limit: Int, $offset: Int, $identity: String) {
    users(name: $name, limit: $limit, offset: $offset, identity: $identity, disable: $disable) {
      id
      name
      realName
      introduction
      gender
      disable
      headshot
      rating
      evaluationNum
      lastSignIn
      phone
      email
      identity
      birth
      ApplyCoach
    }
    totalUser(name: $name, identity: $identity, disable: $disable)
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      disable: // value for 'disable'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      identity: // value for 'identity'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
