import * as antd from 'antd';

// const domain = 'https://peman.caprover.credot-web.com/proxy/storage/';
// const query = '?alt=media&token=31afcf79-d95c-4aa2-a854-5f23a7164d65';

export const Signature = ({ id }: { id: string }) => {
  return (
    <>
      <antd.Image width={200} src={id} />
      {/* <antd.Image width={200} src={`${domain}${id.replace('/', '%2F')}${query}`} /> */}
    </>
  );
};
