import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';

import { PrimaryButton } from '../components/PrimaryButton';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const EditAgent = ({
  onSuccess,
  account,
}: {
  onSuccess: Function;
  account: string;
}) => {
  const appCtx = React.useContext(AppContext);

  React.useEffect(() => {}, []);

  const onFinish = async (values: any) => {
    appCtx.setModal(null);

    const data = await appCtx.fetch('patch', `/api/admin/agent/${account}`, {
      oldPassword: values.oldPassword,
      password: values.password,
    });

    if (data) {
      Notification.add('success', '修改成功');
      onSuccess();
    }
  };

  return (
    <antd.Form onFinish={onFinish}>
      <h5 className="font-weight-bold mb-4">修改{account}密碼</h5>

      {appCtx.account === account && (
        <antd.Form.Item
          name="oldPassword"
          rules={[{ required: true, message: '請輸入舊密碼!' }]}
        >
          <antd.Input.Password
            prefix={<i className="fa fa-lock" />}
            placeholder="請輸入舊密碼"
          />
        </antd.Form.Item>
      )}

      <antd.Form.Item
        name="password"
        rules={[{ required: true, message: '密碼不可以空白!' }]}
      >
        <antd.Input.Password
          prefix={<i className="fa fa-lock" />}
          placeholder="請輸入新密碼"
        />
      </antd.Form.Item>

      <antd.Form.Item
        name="checkPassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: '密碼不可以空白!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('新舊密碼不相符'));
            },
          }),
        ]}
      >
        <antd.Input.Password
          prefix={<i className="fa fa-lock" />}
          placeholder="請再次輸入新密碼"
        />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <PrimaryButton title="修改" htmlType="submit" />
      </antd.Form.Item>
    </antd.Form>
  );
};

export { EditAgent };
