import { ApiClient } from './apiClient';

export interface SalespersonFetchOptions {
  limit?: number;
  offset?: number;
  summaryStart: string;
  summaryEnd: string;
}

export interface SalespersonCreateOptions {
  name: string;
  phone: string;
}

export class Salesperson {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async fetch(options?: SalespersonFetchOptions) {
    const result = await this.apiClient.get('/api/admin/salesperson', { ...options });
    if (result.data.errorCode !== 0) throw new Error(result.data.errorMessage);
    return { data: result.data.data, total: result.data.total };
  }

  async fetchOne(id: number) {
    const result = await this.apiClient.get(`/api/admin/salesperson/${id}`);
    if (result.data.errorCode !== 0) throw new Error(result.data.errorMessage);
    return result.data.data;
  }

  async create(data: SalespersonCreateOptions) {
    const result = await this.apiClient.post('/api/admin/salesperson', { ...data });
    if (result.data.errorCode !== 0) throw new Error(result.data.errorMessage);
  }
}
