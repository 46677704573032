import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import dayjs from 'dayjs';

interface Config {
  latestTokenTime: Date | undefined;
}

const TOKEN = 'token';

export namespace AdminToken {
  const config: Config = {
    latestTokenTime: undefined,
  };

  export function get(): string | undefined {
    return cookie.get(TOKEN);
  }

  export function checkExpire() {
    if (!config.latestTokenTime) return;
    if (dayjs().diff(config.latestTokenTime, 'minutes') >= 5) {
      refresh().catch((reason) => {
        console.log(reason.message || reason);
      });
    }
  }

  export function set(token: string) {
    cookie.set(TOKEN, token);
    config.latestTokenTime = new Date();
  }

  export function remove(): void {
    cookie.remove(TOKEN);
    config.latestTokenTime = undefined;
  }

  export function expiredAt(token?: string): Date | undefined {
    const payload = jwt.decode(token ?? get() ?? '');
    if (!payload || typeof payload === 'string' || !payload.exp) return undefined;
    return new Date(payload.exp * 1000);
  }

  export async function refresh(): Promise<void> {
    const result = await axios.post('/api/admin/token/refresh', undefined, {
      headers: {
        authorization: get(),
      },
    });
    if (result.status === 200 && result.data.errorCode === 0) {
      set(result.data.token);
    }
  }
}
