import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import apiService, { CouponFetchOptions } from '../services/api-service';
import { Button, Input, Select, Spin, Table, Tag } from 'antd';
import dayjs from 'dayjs';

interface Coupon {
  name: string;
  serialNo: string;
  type: string;
  amount: number;
  used: boolean;
  effectiveDate: string;
  expirationDate: string;
  userId: string;
  user: {
    name: string;
    phone: string;
  };
}

const CouponPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<Coupon[]>([]); //columns data
  const [fetchOpt, setFetchOpt] = React.useState<CouponFetchOptions>({
    status: 'none',
  });

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      const data = await apiService.Coupon.fetch({
        ...fetchOpt,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      });
      setLoading(false);

      if (data) {
        setDataSource(data.data);
        setTotal(data.total);
        setCurrentPage(page);
      }
    } catch (error: any) {
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const columns: ColumnsType<Coupon> = [
    {
      title: '名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '學員',
      align: 'center',
      render: (value, record) => {
        return record.user.name;
      },
    },
    {
      title: '學員電話',
      align: 'center',
      render: (value, record) => {
        return record.user.phone;
      },
    },
    {
      title: '面額',
      align: 'center',
      dataIndex: 'amount',
    },
    {
      title: '使用狀態',
      align: 'center',
      dataIndex: 'used',
      render: (value, record) => {
        const [color, text] = value
          ? ['orange', '已使用']
          : dayjs(record.expirationDate).isBefore(dayjs())
          ? ['red', '已過期']
          : ['blue', '未使用'];
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '有效期間',
      align: 'center',
      render: (value, record) => {
        const now = dayjs();
        const effectiveDate = dayjs(record.effectiveDate);
        const expirationDate = dayjs(record.expirationDate);
        const color = effectiveDate.isAfter(now)
          ? 'blue'
          : expirationDate.isBefore(now)
          ? 'red'
          : 'green';
        const effectiveDateText = effectiveDate.format('YYYY-MM-DD');
        const expirationDateText = expirationDate.format('YYYY-MM-DD');
        return <Tag color={color}>{`${effectiveDateText}～${expirationDateText}`}</Tag>;
      },
    },
  ];

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        <Select
          defaultValue={fetchOpt.status}
          style={{ width: 120 }}
          onChange={(select) => {
            setFetchOpt((prevState) => ({ ...prevState, status: select }));
          }}
        >
          <Select.Option value={'none'}>顯示全部</Select.Option>
          <Select.Option value={'unused'}>顯示未使用</Select.Option>
          <Select.Option value={'used'}>顯示已使用</Select.Option>
        </Select>

        <div className="w-3/12">
          <Input
            value={fetchOpt.user}
            onChange={(e) => {
              setFetchOpt((prevState) => ({ ...prevState, user: e.target.value }));
            }}
            allowClear
            placeholder={`請輸入學員姓名`}
          />
        </div>

        <Button type="primary" onClick={() => initialize()}>
          搜尋
        </Button>
      </div>
      <Spin tip="Loading..." spinning={loading}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </Spin>
    </>
  );
};

export { CouponPage };
