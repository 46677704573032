import React from 'react';
import * as antd from 'antd';
import dayjs from 'dayjs';
import { app, storage } from '..//AppContext';

const getURL = async (ref: string) => {
  const storageRef = storage.ref();
  const pathReference = storageRef.child(ref);
  return pathReference
    .getDownloadURL()
    .then(function (url) {
      return url;
    })
    .catch(function (error) {
      console.log(error);
    });
};

interface introductionProps {
  name: string;
  realName?: string;
  introduction: string;
  headshot: string;
  email: string;
  phone: string;
  gender: string;
  birth: string;
}

const Introduction = ({
  name,
  realName,
  introduction,
  headshot,
  email,
  phone,
  gender,
  birth,
}: introductionProps) => {
  const [url, setUrl] = React.useState<string>('');
  React.useEffect(() => {
    const init = async () => {
      if (headshot) {
        try {
          let headshotURL = await getURL(headshot);
          setUrl(headshotURL);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    init();
  });
  return (
    <antd.Descriptions title={name} bordered column={1}>
      <antd.Descriptions.Item label="真實姓名">{realName || '-'}</antd.Descriptions.Item>
      <antd.Descriptions.Item label="性別">{gender}</antd.Descriptions.Item>
      <antd.Descriptions.Item label="生日">
        {dayjs(parseInt(birth)).format('YYYY-MM-DD')}
      </antd.Descriptions.Item>
      <antd.Descriptions.Item label="電話">{phone}</antd.Descriptions.Item>
      <antd.Descriptions.Item label="email">{email}</antd.Descriptions.Item>
      <antd.Descriptions.Item label="介紹">{introduction}</antd.Descriptions.Item>
    </antd.Descriptions>
  );
};

export { Introduction };
