import React from 'react';
import './App.css';
import { AppContext } from './AppContext';
import * as ReactRouterDOM from 'react-router-dom';

import { MainPage } from './pages/MainPage';
import { LaunchPage, LoginPage, NotFoundPage } from './pages/LaunchPage';
import { AgentsPage } from './pages/AgentsPage';
import { UsersPage } from './pages/UsersPage';
import { LessonsPage } from './pages/LessonsPage';
import { EvaluationsPage } from './pages/EvaluationsPage';
import { LessonPricePage } from './pages/LessonPricePage';
import { ContactUsPage } from './pages/ContactUsPage';
import { CompanyInfoPage } from './pages/CompanyInfoPage';
import { RegistsPage } from './pages/RegistsPage';
import { ReportsPage } from './pages/ReportsPage';
import { SalespersonPage } from './pages/SalespersonPage';
import { CouponPage } from './pages/CouponPage';
import { FinishedPage } from './pages/FinishedPage';
import { PaidRefundPage } from './pages/PaidRefundPage';
import { AbsenteeismPage } from './pages/AbsenteeismPage';
import { ApplyInformationPage } from './pages/ApplyInformation';

const menus = [
  {
    key: '/agents',
    title: '管理員',
    icon: 'fa fa-user-secret',
    component: <AgentsPage />,
  },
  {
    key: '/users',
    title: '管理用戶',
    icon: 'fa fa-users',
    component: <UsersPage />,
  },
  {
    key: '/lessons',
    title: '管理課程',
    icon: 'fa fa-calendar',
    component: <LessonsPage />,
  },
  {
    key: '/registsList',
    title: '已預約列表',
    icon: 'fa fa-calendar-o',
    component: <RegistsPage />,
  },
  {
    key: '/finishList',
    title: '已結束列表',
    icon: 'fa fa-calendar-check-o',
    component: <FinishedPage />,
  },
  {
    key: '/absentList',
    title: '失約列表',
    icon: 'fa fa-chain-broken',
    component: <AbsenteeismPage />,
  },
  {
    key: '/evaluations',
    title: '管理評價',
    icon: 'fa fa-star',
    component: <EvaluationsPage />,
  },
  {
    key: '/lessonPrice',
    title: '管理活動',
    icon: 'fa fa-caret-square-o-right',
    component: <LessonPricePage />,
  },
  {
    key: '/salesperson',
    title: '業務人員',
    icon: 'fa fa-address-card-o',
    component: <SalespersonPage />,
  },
  {
    key: '/coupon',
    title: '折價券',
    icon: 'fa fa-ticket',
    component: <CouponPage />,
  },
  {
    key: '/reports',
    title: '統計報表',
    icon: 'fa fa-bar-chart',
    component: <ReportsPage />,
  },
  {
    key: '/paidRefund',
    title: '退款申請列表',
    icon: 'fa fa-balance-scale',
    component: <PaidRefundPage />,
  },
  {
    key: '/contactus',
    title: '聯絡我們',
    icon: 'fa fa-envelope',
    component: <ContactUsPage />,
  },
  {
    key: '/companyinfo',
    title: '公司資訊',
    icon: 'fa fa-address-card',
    component: <CompanyInfoPage />,
  },
  {
    key: '/applyInformation',
    title: '審核資料',
    icon: 'fa fa-address-card',
    component: <ApplyInformationPage />,
  },
];

const App = () => {
  return (
    <ReactRouterDOM.HashRouter>
      <ReactRouterDOM.Switch>
        <ReactRouterDOM.Route path="/" exact component={LaunchPage} />
        <ReactRouterDOM.Route path="/login" component={LoginPage} />

        {menus.map((item) => (
          <ReactRouterDOM.Route key={item.key} path={item.key}>
            <MainPage menus={menus} title={item.title} icon={item.icon} content={item.component} />
          </ReactRouterDOM.Route>
        ))}

        <ReactRouterDOM.Route path="*" component={NotFoundPage} />
      </ReactRouterDOM.Switch>
    </ReactRouterDOM.HashRouter>
  );
};

export default App;
