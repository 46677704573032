import React from 'react';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import dayjs from 'dayjs';

import { Signature } from '../modals/Signature';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const { Option } = antd.Select;

interface regist {
  id: number;
  createAt: Date;
  lessonRegists: lessonRegists;
  user: user;
}

interface lessonRegists {
  user: user;
  durationStart: Date;
  durationEnd: Date;
  lesson: lesson;
}
interface user {
  name: string;
  phone: string;
}
interface lesson {
  name: string;
  user: user;
}

export const AbsenteeismPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<regist[]>([]); //coulmns data

  const [input, setInput] = React.useState<string>('');
  const [select, setSelect] = React.useState<string>('1');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (page - 1)).toString());
      url.append('limit', pageSize.toString());

      const result = await appCtx.fetch(
        'get',
        '/api/admin//lessonregists/report?' + url.toString(),
      );
      setLoading(false);
      if (result) {
        // console.log(result);
        setTotal(result.total);
        setDataSource(result.absent);
        setCurrentPage(page);

        console.log(result.absent);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  const absentConfirm = async (id: string, confirm: boolean) => {
    try {
      await appCtx.fetch('patch', `/api/admin/lessonregists/report/${id}`, { confirm });
      initialize();
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const checkStatus = (status: string): string => {
    switch (status) {
      case 'review':
        return '審核中';
      case 'confirm':
        return '確認失約';
      default:
        return '未失約'; // discard
    }
  };

  const columns: ColumnsType<regist> = [
    {
      title: '課程名稱',
      align: 'center',
      render: (item) => <>{item.lessonRegists.lesson.name}</>,
    },
    {
      title: '回報人',
      align: 'center',
      render: (item) => <>{item.user.name}</>,
    },
    {
      title: '教練名稱',
      align: 'center',
      render: (item) => <>{item.lessonRegists.lesson.user.name}</>,
    },
    {
      title: '教練電話',
      align: 'center',
      render: (item) => <>{item.lessonRegists.lesson.user.phone}</>,
    },
    {
      title: '學員名稱',
      align: 'center',
      render: (item) => <>{item.lessonRegists.user.name}</>,
    },
    {
      title: '學員電話',
      align: 'center',
      render: (item) => <>{item.lessonRegists.user.phone}</>,
    },
    {
      title: '課程時間',
      align: 'center',
      render: (item) => (
        <>{`${dayjs(item.lessonRegists.timeStart).format('YYYY-MM-DD HH:mm:ss')}~${dayjs(
          item.lessonRegists.timeEnd,
        ).format('HH:mm:ss')}`}</>
      ),
    },
    {
      title: '失約狀態',
      align: 'center',
      render: (item) => (
        <antd.Tag
          color={
            item.status === 'review' ? 'magenta' : item.status === 'confirm' ? 'purple' : 'green'
          }
        >
          {checkStatus(item.status)}
        </antd.Tag>
      ),
    },
    {
      title: '失約審核',
      align: 'center',
      render: (item) =>
        item.status === 'review' ? (
          <antd.Popover
            placement="bottom"
            content={
              <div className="d-flex flex-column">
                <antd.Button type="link" danger onClick={() => absentConfirm(item.id, true)}>
                  確認失約
                </antd.Button>
                <antd.Button type="link" danger onClick={() => absentConfirm(item.id, false)}>
                  未失約
                </antd.Button>
              </div>
            }
          >
            <antd.Button type="primary">審核</antd.Button>
          </antd.Popover>
        ) : (
          <></>
        ),
    },
  ];

  // const SelectAfter = (
  //   <antd.Select
  //     defaultValue={select}
  //     style={{ width: 120 }}
  //     onChange={(select) => setSelect(select)}
  //   >
  //     <Option value="1">課程名稱</Option>
  //     <Option value="2">教練名稱</Option>
  //     <Option value="3">學員名稱</Option>
  //   </antd.Select>
  // );

  return (
    <>
      {/* <div className="flex space-x-4  mb-3 ">
        <div className="w-3/12">
          <antd.Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            allowClear
            addonBefore={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div> */}
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};
