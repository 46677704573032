import React from 'react';
import ReactDOM from 'react-dom';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import * as cookie from 'js-cookie';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { DangerButton } from '../components/DangerButton';
import { Introduction } from '../modals/Introduction';
import { AddUser } from '../modals/Adduser';
import { PrimaryButton } from '../components/PrimaryButton';

import { UsersDocument, useUsersQuery, UsersQuery, User } from '../gplhook/gpl';

const { Option } = antd.Select;

const UsersPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<User[]>([]); //coulmns data

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const [selectIdentity, setSelectIdentity] = React.useState<string>('1');
  const [selectDisable, setSelectDisable] = React.useState<string>('1');
  const [username, setUserName] = React.useState<string>('');

  const { loading, error, data, refetch } = useUsersQuery({
    variables: {
      name: username,
      disable: selectDisable === '1' ? null : selectDisable === '2' ? false : true,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      identity: selectIdentity === '1' ? null : selectIdentity === '2' ? 'coach' : 'user',
    },
  });

  React.useEffect(() => {
    setDataSource(data?.users || []);
    setTotal(data?.totalUser || 0);
  }, [data]);

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
  }, []);

  if (error) {
    console.log(`graphql query fail, error: ${JSON.stringify(error)} `);
    Notification.add('error', error.message);
  }

  const onDisable = async (checked: boolean, account: string) => {
    if (!appCtx.isAdmin) {
      Notification.add('error', '帳號權限不足');
      return;
    }
    try {
      let data = await appCtx.fetch('patch', `/api/admin/user/disable/${account}/${checked}`);
      refetch();
    } catch (error: any) {
      Notification.add('error', '請求失敗');
    }
  };

  const columns: ColumnsType<User> = [
    {
      title: '用戶名稱',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '身份',
      align: 'center',
      render: (item) => (
        <antd.Tag color={item.identity === 'coach' ? 'red' : 'blue'}>
          {item.identity === 'coach' ? '教練' : '用戶'}
        </antd.Tag>
      ),
    },
    {
      title: '啟用中',
      align: 'center',
      render: (item) => (
        <antd.Switch
          disabled={!appCtx.isAdmin}
          checked={!item.disable}
          onChange={(checked) =>
            antd.Modal.confirm({
              title: '確認',
              icon: <i />,
              content: checked ? '確認解除停用?' : '確認停用帳號?',
              okText: '確認',
              cancelText: '取消',
              onOk: () => onDisable(!checked, item.id),
            })
          }
        />
      ),
    },
    {
      title: '評價 / 評價數量',
      align: 'center',
      render: (item) =>
        item.evaluationNum ? <p>{`${item.rating} / ${item.evaluationNum}`}</p> : null,
    },
    {
      title: '詳細內容',
      align: 'center',
      render: (item) => (
        <antd.Button
          type="primary"
          onClick={() => {
            appCtx.setModal(
              <Introduction
                name={item.name}
                realName={item.realName}
                introduction={item.introduction}
                headshot={item.headshot}
                phone={item.phone}
                email={item.email}
                gender={item.gender}
                birth={item.birth}
              />,
            );
          }}
        >
          詳細內容
        </antd.Button>
      ),
    },
    {
      title: '最後登入時間',
      align: 'center',
      render: (item) => <>{dayjs(item.lastSignIn).format('YYYY-MM-DD HH:mm:ss')}</>,
    },

    {
      title: '申請成為教練',
      align: 'center',
      render: (item) =>
        item.ApplyCoach === 0 ? (
          <></>
        ) : (
          <div className="flex justify-center space-x-1">
            <PrimaryButton
              title="同意"
              onClick={() => {
                antd.Modal.confirm({
                  title: '確認',
                  icon: <i />,
                  content: '是否要同意申請',
                  okText: '確認',
                  cancelText: '取消',
                  onOk: async () => {
                    await appCtx.fetch('put', '/api/admin/account/applycoach/' + item.ApplyCoach, {
                      result: true,
                    });
                    Notification.add('success', '已同意申請');
                    refetch();
                  },
                });
              }}
            />
            <DangerButton
              title="拒絕"
              message="是否要拒絕申請"
              onClick={async () => {
                await appCtx.fetch('put', '/api/admin/account/applycoach/' + item.ApplyCoach, {
                  result: false,
                });
                Notification.add('success', '已拒絕申請');
                refetch();
              }}
            />
          </div>
        ),
    },
  ];

  const SelectAfter = (
    <antd.Select
      defaultValue={selectIdentity}
      style={{ width: 100 }}
      onChange={(select) => setSelectIdentity(select)}
    >
      <Option value="1">所有身份</Option>
      <Option value="2">教練</Option>
      <Option value="3">一般用戶</Option>
    </antd.Select>
  );

  return (
    <>
      <div className="flex mb-3 space-x-4 ">
        {appCtx.isAdmin && (
          <>
            <antd.Button
              type="primary"
              onClick={() => {
                appCtx.setModal(<AddUser onSuccess={() => refetch()} />);
              }}
            >
              新增教練
            </antd.Button>
            <div style={{ marginTop: 10 }} />
          </>
        )}
        <div>
          <antd.Input
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            allowClear
            addonAfter={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Select
          defaultValue={selectDisable}
          style={{ width: 150 }}
          onChange={(select) => setSelectDisable(select)}
        >
          <Option value="1">全部用戶</Option>
          <Option value="2">僅啟用中用戶</Option>
          <Option value="3">僅停用中用戶</Option>
        </antd.Select>

        <antd.Button type="primary" onClick={() => refetch()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      </antd.Spin>
    </>
  );
};

export { UsersPage };
