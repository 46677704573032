import React from 'react';
import * as antd from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as cookie from 'js-cookie';
import dayjs from 'dayjs';

import { Signature } from '../modals/Signature';
import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const { Option } = antd.Select;

interface regist {
  id: number;
  date: Date;
  timeStart: Date;
  timeEnd: Date;
  lesson: lesson;
  user: user;
}

interface lesson {
  name: string;
  user: user;
}
interface user {
  name: string;
}

export const FinishedPage = () => {
  const appCtx = React.useContext(AppContext);
  const [dataSource, setDataSource] = React.useState<regist[]>([]); //coulmns data

  const [input, setInput] = React.useState<string>('');
  const [select, setSelect] = React.useState<string>('1');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const initialize = async (page: number = currentPage) => {
    try {
      setLoading(true);
      let url = new URLSearchParams();
      url.append('offset', (pageSize * (page - 1)).toString());
      url.append('limit', pageSize.toString());
      url.append('status', 'finish');
      switch (select) {
        case '1':
          url.append('name', input);
          break;
        case '2':
          url.append('coachName', input);
          break;
        default:
          url.append('userName', input);
          break;
      }

      let result = await appCtx.fetch('get', '/api/admin/lessonregists?' + url.toString());
      setLoading(false);
      if (result) {
        console.log(result);
        setTotal(result.total);
        setDataSource(result.regists);
        setCurrentPage(page);
      }
    } catch (error: any) {
      console.log(error.message);
      Notification.add('error', error.message);
    }
  };

  React.useEffect(() => {
    let user = JSON.parse(cookie.get('user') || '{}');
    appCtx.setAccount(user.id);
    appCtx.setIsAdmin(!!user.admin);
    initialize();
  }, []);

  const checkStatus = (status: string): string => {
    switch (status) {
      case 'failed':
        return '付款失敗';
      case 'signed':
        return '已簽名';
      case 'cancel':
        return '課程已取消';
      case 'abandon':
        return '放棄付款';
      case 'forceFinish':
        return '強制購課';
      case 'absenteeism':
        return '失約';
      default:
        return '未知狀態';
    }
  };

  const columns: ColumnsType<regist> = [
    {
      title: '課程名稱',
      align: 'center',
      render: (item) => <>{item.lesson.name}</>,
    },
    {
      title: '課程狀態',
      align: 'center',
      render: (item) => (
        <antd.Tag
          color={
            item.status === 'signed'
              ? 'green'
              : item.status === 'forceFinish'
              ? 'purple'
              : 'magenta'
          }
        >
          {checkStatus(item.status)}
        </antd.Tag>
      ),
    },
    {
      title: '教練',
      align: 'center',
      render: (item) => <>{item.lesson.user.name}</>,
    },
    {
      title: '學員',
      align: 'center',
      render: (item) => <>{item.user.name}</>,
    },
    {
      title: '起始時間',
      align: 'center',
      render: (item) => <>{dayjs(item.timeStart).format('YYYY-MM-DD HH:mm:ss')}</>,
    },
    {
      title: '結束時間',
      align: 'center',
      render: (item) => <>{dayjs(item.timeEnd).format('YYYY-MM-DD HH:mm:ss')}</>,
    },
    {
      title: '上課地點',
      align: 'center',
      render: (item) => (
        <>{`${item.lesson.counties}${item.lesson.district}${item.lesson.section}`}</>
      ),
    },
    {
      align: 'center',
      render: (item) => (
        <antd.Button
          disabled={!item.signatureId}
          type="primary"
          onClick={() => appCtx.setModal(<Signature id={item.signatureId} />)}
        >
          {item.signatureId ? '查看簽名' : '客戶未簽名'}
        </antd.Button>
      ),
    },
  ];

  const SelectAfter = (
    <antd.Select
      defaultValue={select}
      style={{ width: 120 }}
      onChange={(select) => setSelect(select)}
    >
      <Option value="1">課程名稱</Option>
      <Option value="2">教練名稱</Option>
      <Option value="3">學員名稱</Option>
    </antd.Select>
  );

  return (
    <>
      <div className="flex space-x-4  mb-3 ">
        <div className="w-3/12">
          <antd.Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            allowClear
            addonBefore={SelectAfter}
            placeholder={`請輸入搜尋條件`}
          />
        </div>

        <antd.Button type="primary" onClick={() => initialize()}>
          搜尋
        </antd.Button>
      </div>
      <antd.Spin tip="Loading..." spinning={loading}>
        <antd.Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page) => initialize(page),
          }}
        />
      </antd.Spin>
    </>
  );
};
